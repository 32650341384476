import {widgetChangeLayout} from '@wix/bi-logger-events-users/v2'
import {LIST_LAYOUT, WIDGET_LAYOUT, ExperimentNames, isRtlLanguage} from '@wix/wix-events-commons-statics'
import {setSiteSettings} from '../../../../commons/actions/site-settings'
import {getUserTimezone} from '../../../../commons/utils/wix-code-api'
import {WidgetSettingsEventsKeys} from '../../interfaces'
import {isMembersInstalled, isPaidPlansInstalled} from '../selectors/installed-apps'
import {createAsyncAction} from '../services/redux-toolkit'
import {setDefaultDimensions} from '../services/resize'
import {fillSettingsDefaults} from '../../defaultParams'
import {WidgetComponentSettings} from '../../stylesParams'
import {WidgetComponentConfig} from '../../Widget/types/state'

export const fetchComponentConfig = createAsyncAction<WidgetComponentConfig>(
  'FETCH_COMPONENT_CONFIG',
  async (
    _,
    {
      getState,
      dispatch,
      extra: {
        serverApi,
        Wix,
        environment: {language, isEditorX},
        experiments,
        t,
      },
    },
  ) => {
    const state = getState()
    const splitRegButtonDefaults = experiments.enabled(ExperimentNames.SplitRegButtonDefaults)
    const responsive = isEditorX

    const {siteSettings, component} = await serverApi.getWidgetData({
      viewMode: 'editor',
      locale: Wix.Utils.getLocale(),
      language,
      compId: Wix.Utils.getOrigCompId(),
      tz: getUserTimezone() || 'UTC',
      members: state.installedApps.members,
      paidPlans: state.installedApps.paidPlans,
      responsive,
      showcase: true,
    })
    await dispatch(setSiteSettings({settings: siteSettings}))

    return {
      id: component.id,
      params: {},
      byCategoryId: component.config.byCategoryId,
      byEventId: component.config.byEventId,
      byStatus: component.config.byStatus,
      settings: fillSettingsDefaults(component.config?.settings as WidgetComponentSettings, {
        rtl: isRtlLanguage(language),
        responsive,
        t,
        splitRegButtonDefaults,
      }),
    }
  },
)

export const updateComponentDraft = createAsyncAction<
  wix.events.editor.WebComponent,
  wix.events.editor.WebComponentConfig
>('UPDATE_COMPONENT_DRAFT', async (componentConfig, {getState, dispatch, extra: {serverApi, Wix, settings}}) => {
  const state = getState()
  const members = isMembersInstalled(state)
  const paidPlans = isPaidPlansInstalled(state)
  const payload = await serverApi.updateComponentDraft({
    componentConfig,
    originalComponentId: Wix.Utils.getOrigCompId(),
    members,
    paidPlans,
  })

  settings.publishEvent(WidgetSettingsEventsKeys.InjectComponentDraft, payload)

  dispatch({
    type: 'INJECT_COMPONENT_DRAFT',
    payload,
  })

  return payload.component
})

interface WidgetLayoutChangedParams {
  layout: LIST_LAYOUT | WIDGET_LAYOUT
  singleEventWidget: boolean
}

export const widgetLayoutChanged = createAsyncAction<void, WidgetLayoutChangedParams>(
  'WIDGET_LAYOUT_CHANGED',
  async ({layout, singleEventWidget}, {extra: {bi, settings, editorSDK, Wix}, getState}) => {
    settings.publishEvent(WidgetSettingsEventsKeys.InjectComponentDraft, null)

    bi.report(
      widgetChangeLayout({
        instance_id: Wix.Utils.getInstanceId(),
        display_type: singleEventWidget ? 'single' : 'multiple',
        layout_type: widgetLayoutToString(layout, singleEventWidget),
      }),
    )

    setDefaultDimensions({Wix, singleEventWidget, editorSDK, layout, state: getState()})
  },
)

const widgetLayoutToString = (layout: WIDGET_LAYOUT | LIST_LAYOUT, singleEventWidget: boolean) => {
  if (singleEventWidget) {
    switch (layout as WIDGET_LAYOUT) {
      case WIDGET_LAYOUT.BUTTON_ONLY:
        return 'button_only'
      case WIDGET_LAYOUT.BACKGROUND:
        return 'background'
      case WIDGET_LAYOUT.VERTICAL:
        return 'vertical'
      case WIDGET_LAYOUT.FULL:
      default:
        return 'full'
    }
  } else {
    switch (layout as LIST_LAYOUT) {
      case LIST_LAYOUT.SIDE_BY_SIDE:
        return 'side_by_side'
      case LIST_LAYOUT.CALENDAR:
        return 'calendar'
      case LIST_LAYOUT.LIST:
        return 'list'
      case LIST_LAYOUT.NEW_GRID:
      default:
        return 'new_grid'
    }
  }
}
